import { TFunction, Trans } from "react-i18next";

import { TermSearchType } from "@sellernote/_shared/src/hooks/admin/useSearchWithTermWithHistorySave";

import {
  BooleanStringV2,
  FreightType,
  InlnadTransportType,
  ItemUnitMeasurement,
  Option,
} from "../../types/common/common";
import { BidSearchType } from "../../types/forwarding/adminBid";
import {
  AdminPromotionDeactivateReason,
  AdminPromotionDetailStatus,
  AdminPromotionStatus,
} from "../../types/forwarding/adminPromotion";
import {
  BidProjectStatus,
  BidStatus,
  ContainerCategory,
  ContainerType,
} from "../../types/forwarding/bid";
import { PartnerBusinessArea } from "../../types/forwarding/partner";
import { ImportDutyList } from "../../types/forwarding/tradingStatement";
import { BLType, CreateDocument } from "../../types/forwarding/trello";

import { InputSelectOption } from "../../headlessComponents/input/useInputSelect";
import { getObjectEntries } from "./object";

//TODO: 새로 추가된 constants 폴더로 옮기기
/**
 * 포장 타입 옵션리스트
 */
const packageOptionList = [
  { label: "PALLET", value: "pallets" },
  { label: "BOX", value: "boxes" },
  { label: "CARTON", value: "cartons" },
  { label: "CRATE", value: "crates" },
  { label: "DRUM", value: "drums" },
  { label: "BAG", value: "bags" },
  { label: "BALE", value: "bales" },
  { label: "BUNDLE", value: "bundles" },
  { label: "CAN", value: "cans" },
  { label: "CARBOY", value: "carboys" },
  { label: "CARPET", value: "carpets" },
  { label: "CASE", value: "cases" },
  { label: "COIL", value: "coils" },
  { label: "CYLINDER", value: "cylinders" },
  { label: "LOOSE", value: "loose" },
  { label: "PAIL", value: "pails" },
  { label: "REEL", value: "reels" },
  { label: "ROLL", value: "rolls" },
  { label: "TUBE", value: "tubes" },
  { label: "PIPE", value: "pipes" },
  { label: "OTHER", value: "other" },
];

const SIZE_UNIT_OPTION_LIST = [
  { label: "M", value: "m" },
  { label: "CM", value: "cm" },
];

const VOLUME_UNIT_OPTION_LIST = [
  { label: "M", value: "M" },
  { label: "CM", value: "CM" },
];

const VOLUME_UNIT_AS_CBM_OPTION_LIST = [
  {
    label: "CBM",
    value: "CBM",
  },
];

const WEIGHT_UNIT_OPTION_LIST = [
  { label: "TON", value: "TON" },
  { label: "KG", value: "KG" },
];

const CONTAINER_CATEGORY_OPTION_LIST = [
  {
    label: <Trans i18nKey="utils:CONTAINER_CATEGORY_OPTION_LIST.DRY_일반_" />,
    value: "DRY",
  },
  {
    label: (
      <Trans i18nKey="utils:CONTAINER_CATEGORY_OPTION_LIST.REEFER_냉동_" />
    ),
    value: "REEFER",
  },
  {
    label: (
      <Trans i18nKey="utils:CONTAINER_CATEGORY_OPTION_LIST.OPEN_TOP_오픈탑_" />
    ),
    value: "OPEN_TOP",
  },
  {
    label: (
      <Trans i18nKey="utils:CONTAINER_CATEGORY_OPTION_LIST.FLAT_RACK_플랫랙_" />
    ),
    value: "FLAT_RACK",
  },
];

const CONTAINER_TYPE_OPTION_LIST: Record<
  ContainerCategory,
  InputSelectOption<ContainerType>[]
> = {
  DRY: [
    {
      label: "20DRY",
      value: "20DRY",
    },
    {
      label: "40DRY",
      value: "40DRY",
    },
    {
      label: "40HQ",
      value: "40HQ",
    },
  ],
  REEFER: [
    {
      label: "20RF",
      value: "20RF",
    },
    {
      label: "40RF",
      value: "40RF",
    },
  ],
  OPEN_TOP: [
    {
      label: "20OT",
      value: "20OT",
    },
    {
      label: "40OT",
      value: "40OT",
    },
    {
      label: "20OT(OH)",
      value: "20OT(OH)",
    },
    {
      label: "40OT(OH)",
      value: "40OT(OH)",
    },
  ],
  FLAT_RACK: [
    {
      label: "20FR",
      value: "20FR",
    },
    {
      label: "40FR",
      value: "40FR",
    },
    {
      label: "20FR(OH)",
      value: "20FR(OH)",
    },
    {
      label: "40FR(OH)",
      value: "40FR(OH)",
    },
    {
      label: "20FR(OW)",
      value: "20FR(OW)",
    },
    {
      label: "40FR(OW)",
      value: "40FR(OW)",
    },
    {
      label: "20FR(OWH)",
      value: "20FR(OWH)",
    },
    {
      label: "40FR(OWH)",
      value: "40FR(OWH)",
    },
  ],
};

const INLAND_PARTNER_OPTION_LIST: Option<InlnadTransportType>[] = [
  { label: "독차 정보", value: "sole" },
  { label: "합차 정보", value: "consol" },
  { label: "택배사 정보", value: "parcel" },
];

const PROMOTION_STATUS_OPTION_LIST: Option<AdminPromotionStatus | undefined>[] =
  [
    { label: "전체", value: undefined },
    { label: "진행 중", value: "inProgress" },
    { label: "대기", value: "waiting" },
    { label: "보류", value: "pending" },
    { label: "취소", value: "canceled" },
    { label: "완료", value: "finished" },
  ];

const PROMOTION_DEACTIVATE_REASON_OPTION_LIST: Option<AdminPromotionDeactivateReason>[] =
  [
    { label: "쉽다 이용고객", value: "previousUser" },
    { label: "LCL", value: "freightTypeLcl" },
    { label: "AIR", value: "freightTypeAir" },
    { label: "특수컨테이너", value: "specialContainer" },
  ];

const PROMOTION_CANCELED_OPTION_LIST: Option<AdminPromotionDetailStatus>[] = [
  { label: "수입견적 제출불가", value: "unableQuotation" },
  { label: "연락 두절", value: "lostContact" },
  { label: "고객의 취소요청", value: "cancellationRequest" },
  { label: "중복신청", value: "duplicate" },
  { label: "기타", value: "etc" },
];

const PROMOTION_PENDING_OPTION_LIST: Option<AdminPromotionDetailStatus>[] = [
  { label: "견적서 요청 중", value: "waiting" },
  { label: "부재 중", value: "missed" },
];

const IMPORT_DUTY_OPTION_LIST: Option<ImportDutyList>[] = [
  { label: "관세", value: "관세" },
  { label: "부가가치세", value: "부가가치세" },
  { label: "교통에너지환경세", value: "교통에너지환경세" },
  { label: "개별소비세", value: "개별소비세" },
  { label: "주세", value: "주세" },
  { label: "교육세", value: "교육세" },
  { label: "농어촌특별세", value: "농어촌특별세" },
  { label: "가산세(보정이자)", value: "가산세(보정이자)" },
  { label: "가산금", value: "가산금" },
  { label: "기타", value: "기타" },
];

const ITEM_UNIT_MEASUREMENT_OPTION_LIST: Option<ItemUnitMeasurement>[] = [
  { label: "미선택", value: "" },
  { label: "R.TON", value: "R.TON" },
  { label: "C/W", value: "C/W" },
  { label: "CNTR", value: "CNTR" },

  /** 프리타임 적용 시 필요한 견적 단위 */
  { label: "CNTR/DAY", value: "CNTR/DAY" },
  { label: "R.TON/DAY", value: "R.TON/DAY" },

  { label: "B/L", value: "B/L" },
  { label: "ENTRY", value: "ENTRY" },
  { label: "TRUCK", value: "TRUCK" },
  { label: "BOX", value: "BOX" },
  { label: "0.1 R.TON", value: "0.1 R.TON" },
  { label: "3R.TON", value: "3R.TON" },
  { label: "CNTR(20DRY)", value: "CNTR(20DRY)" },
  { label: "CNTR(40DRY)", value: "CNTR(40DRY)" },
  { label: "CNTR(40HQ)", value: "CNTR(40HQ)" },
  { label: "20FR", value: "20FR" },
  { label: "40FR", value: "40FR" },
  { label: "20RF", value: "20RF" },
  { label: "40RF", value: "40RF" },
  { label: "20OT", value: "20OT" },
  { label: "40OT", value: "40OT" },
];

/**
 * LCL, AIR 의뢰는 PALLET, CRATE, DRUM 견적단위 항목 추가
 */
const ITEM_UNIT_MEASUREMENT_OPTION_LIST_FOR_LCL_AND_AIR: Option<ItemUnitMeasurement>[] =
  [
    { label: "미선택", value: "" },
    { label: "R.TON", value: "R.TON" },
    { label: "C/W", value: "C/W" },
    { label: "CNTR", value: "CNTR" },
    { label: "B/L", value: "B/L" },
    { label: "ENTRY", value: "ENTRY" },
    { label: "TRUCK", value: "TRUCK" },
    { label: "BOX", value: "BOX" },
    { label: "PALLET", value: "PALLET" },
    { label: "CRATE", value: "CRATE" },
    { label: "DRUM", value: "DRUM" },
    { label: "0.1 R.TON", value: "0.1 R.TON" },
    { label: "3R.TON", value: "3R.TON" },
    { label: "CNTR(20DRY)", value: "CNTR(20DRY)" },
    { label: "CNTR(40DRY)", value: "CNTR(40DRY)" },
    { label: "CNTR(40HQ)", value: "CNTR(40HQ)" },
    { label: "20FR", value: "20FR" },
    { label: "40FR", value: "40FR" },
    { label: "20RF", value: "20RF" },
    { label: "40RF", value: "40RF" },
    { label: "20OT", value: "20OT" },
    { label: "40OT", value: "40OT" },
  ];

const BID_STATUS_OPTION_LIST: Option<BidStatus>[] = [
  { label: "의뢰 받음", value: "committed" },
  { label: "견적 산출 중", value: "estimating" },
  { label: "사용자 선택 대기 중", value: "waiting" },
  { label: "수출자 정보 입력 대기 중", value: "waitingForExporterInfo" },
  { label: "진행 중", value: "inProgress" },
  { label: "완전 종료", value: "finished" },
];

const BL_TYPE_OPTION_LIST: Option<BLType>[] = [
  { label: "MBL/HBL", value: "ALL" },
  { label: "HBL", value: "HBL" },
  { label: "MBL", value: "MBL" },
  { label: "MBL Direct", value: "DirectMBL" },
  // AIR 전용 문구(MAWB, HAWB)
  { label: "MAWB/HAWB", value: "ALL" },
  { label: "MAWB Direct", value: "DirectMBL" },
];

const BID_PROJECT_STATUS_OPTION_LIST: Option<BidProjectStatus>[] = [
  {
    label: "수출자 컨택 전",
    value: "beforeContactPartner",
  },
  {
    label: "수출자 컨택 중",
    value: "contactingPartner",
  },
  {
    label: "출항 준비 중",
    value: "scheduling",
  },
  {
    label: "출항 완료",
    value: "moving",
  },
  {
    label: "입항완료 및 통관준비",
    value: "portEntryAndPrepareCustoms",
  },
  {
    label: "정산 요청",
    value: "payment",
  },
  {
    label: "통관 완료",
    value: "completeCustoms",
  },
  {
    label: "내륙 운송 중",
    value: "delivering",
  },
  {
    label: "완료",
    value: "finished",
  },
  {
    label: "정산 중",
    value: "inSettlement",
  },
  {
    label: "정산 반려",
    value: "settlementRejected",
  },
  {
    label: "정산 완료",
    value: "settlementComplete",
  },
  {
    label: "취소",
    value: "canceled",
  },
  {
    label: "실패",
    value: "failed",
  },
];

const BID_LIST_SEARCH_TYPE_OPTION_LIST: TermSearchType<BidSearchType>[] = [
  {
    label: "회사명",
    value: "company",
  },
  {
    label: "팀명",
    value: "teamName",
  },
  {
    label: "BL(AWB)",
    value: "BL",
  },
  {
    label: "PO",
    value: "poNumber",
  },
  {
    label: "운송의뢰번호",
    value: "id",
    type: "number",
  },
  {
    label: "담당자 이름",
    value: "userName",
  },
  {
    label: "담당자 연락처",
    value: "userPhone",
  },
  {
    label: "담당자 이메일",
    value: "userEmail",
  },
];

const BID_FREIGHT_TYPE_OPTION_LIST: Option<FreightType | "all">[] = [
  {
    label: "ALL",
    value: "all",
  },
  {
    label: "FCL",
    value: "FCL",
  },

  {
    label: "LCL",
    value: "LCL",
  },
  {
    label: "AIR",
    value: "AIR",
  },
];

const CREATE_DOCUMENT_TYPE_OPTION_LIST: Option<CreateDocument>[] = [
  {
    label: "House D/O",
    value: "houseDo",
  },
  {
    label: "보세창고 출고 요청서",
    value: "releaseRequest",
  },
  {
    label: "택배 운송 요청서",
    value: "parcelRequest",
  },
];

const SETTLEMENT_PROJECT_STATUS_OPTION_LIST: Option<BidProjectStatus>[] = [
  {
    label: "정산 중",
    value: "inSettlement",
  },
  {
    label: "정산 반려",
    value: "settlementRejected",
  },
  {
    label: "정산 완료",
    value: "settlementComplete",
  },
];

const TRELLO_PURCHASE_PARTNER_OPTION_LIST: Option<PartnerBusinessArea>[] = [
  {
    label: "창고",
    value: "wareHouse",
  },
  {
    label: "선사",
    value: "shipping",
  },
  {
    label: "세관",
    value: "customsDuty",
  },
  {
    label: "기타",
    value: "etc",
  },
  {
    label: "해외파트너",
    value: "foreign",
  },
  {
    label: "국내파트너",
    value: "domestic",
  },
  {
    label: "내륙운송사",
    value: "inland",
  },
];

/**
 * BOOLEAN_STRING_OPTION_LIST을 써야하는데 label을 string으로 반환받아야하는 경우 사용
 * (ex. RadioGroup컴포넌트의 option으로 사용)
 *
 * @param t - 다국어 번역 함수
 * @returns BooleanString 옵션 리스트를 문자열 라벨로 반환
 */
function getBooleanStringOptionListAsStringLabel(t: TFunction<"common"[]>) {
  return {
    YES_OR_NO: [
      {
        label: t("common:no"),
        value: "FALSE" as BooleanStringV2,
      },
      {
        label: t("common:yes"),
        value: "TRUE" as BooleanStringV2,
      },
    ],
    POSSIBILITY: [
      {
        label: t("common:possible"),
        value: "TRUE" as BooleanStringV2,
      },
      {
        label: t("common:impossible"),
        value: "FALSE" as BooleanStringV2,
      },
    ],
    CONTAINS: [
      {
        label: t("common:contain"),
        value: "TRUE" as BooleanStringV2,
      },
      {
        label: t("common:uncontain"),
        value: "FALSE" as BooleanStringV2,
      },
    ],
  };
}

/**
 * BooleanString을 토글할때 사용
 * undefined를 전달받을때는 'FALSE'로 간주되어 'TRUE'가 반환됨에 유의
 *
 * @param booleanString - 토글할 BooleanString 값
 * @returns 반대의 BooleanString 값
 *
 * @remarks
 * undefined를 전달받을 때는 'FALSE'로 간주되어 'TRUE'가 반환됨
 */
function getReverseBooleanString(booleanString: BooleanStringV2 | undefined) {
  if (booleanString === "TRUE") {
    return "FALSE";
  }

  if (booleanString === "FALSE") {
    return "TRUE";
  }

  return "TRUE";
}

/**
 * 선택된 옵션이 없고, 남은 옵션이 하나일 경우 자동 선택
 *
 * @param optionList - 옵션 리스트
 * @param hasSelectedOption - 선택된 옵션이 있는지 여부
 * @param onSelectOption - 옵션 선택 콜백 함수
 * @returns 없음
 */
function autoSelectOnlyOneLeftOption<T>({
  optionList,
  hasSelectedOption,
  onSelectOption,
}: {
  optionList: T[] | undefined;
  hasSelectedOption: boolean;
  onSelectOption: (option: T) => void;
}) {
  if (hasSelectedOption) return;

  if (optionList?.length !== 1) return;

  onSelectOption(optionList[0]);
}

/**
 * Record 타입의 Option 리스트를 선택된 값에 따라 disabled 처리
 *
 * @param optionList - 옵션 리스트
 * @param selectedValues - 선택된 값 리스트
 * @returns disabled 처리한 Option 리스트
 */
function disableSelectedValuesForOptionList<T extends string, K>(
  optionList: Record<T, InputSelectOption<K>[]>,
  selectedValues: K[]
) {
  const optionsArray = getObjectEntries(optionList).map(([key, values]) => [
    key,
    (values as InputSelectOption<K>[]).map((option) =>
      selectedValues.includes(option.value)
        ? { ...option, disabled: true }
        : option
    ),
  ]);

  return Object.fromEntries(optionsArray);
}

/**
 * Record 타입의 Option 리스트를 선택된 값에 따라 옵션을 제거
 *
 * @param optionList - 옵션 리스트
 * @param selectedValues - 선택된 값 리스트
 * @returns 선택한 값을 제외한 Option 리스트
 */
function filterSelectedValuesForOptionList<T extends string, K>(
  optionList: Record<T, InputSelectOption<K>[]>,
  selectedValues: K[]
) {
  return Object.fromEntries(
    getObjectEntries(optionList).map(([key, values]) => [
      key,
      (values as InputSelectOption<K>[]).filter(
        (option) => !selectedValues.includes(option.value)
      ),
    ])
  );
}

export {
  packageOptionList,
  getBooleanStringOptionListAsStringLabel,
  getReverseBooleanString,
  autoSelectOnlyOneLeftOption,
  VOLUME_UNIT_OPTION_LIST,
  SIZE_UNIT_OPTION_LIST,
  WEIGHT_UNIT_OPTION_LIST,
  VOLUME_UNIT_AS_CBM_OPTION_LIST,
  CONTAINER_CATEGORY_OPTION_LIST,
  CONTAINER_TYPE_OPTION_LIST,
  INLAND_PARTNER_OPTION_LIST,
  PROMOTION_STATUS_OPTION_LIST,
  PROMOTION_DEACTIVATE_REASON_OPTION_LIST,
  PROMOTION_CANCELED_OPTION_LIST,
  PROMOTION_PENDING_OPTION_LIST,
  IMPORT_DUTY_OPTION_LIST,
  ITEM_UNIT_MEASUREMENT_OPTION_LIST,
  ITEM_UNIT_MEASUREMENT_OPTION_LIST_FOR_LCL_AND_AIR,
  BID_STATUS_OPTION_LIST,
  BID_PROJECT_STATUS_OPTION_LIST,
  BID_LIST_SEARCH_TYPE_OPTION_LIST,
  BID_FREIGHT_TYPE_OPTION_LIST,
  CREATE_DOCUMENT_TYPE_OPTION_LIST,
  SETTLEMENT_PROJECT_STATUS_OPTION_LIST,
  TRELLO_PURCHASE_PARTNER_OPTION_LIST,
  BL_TYPE_OPTION_LIST,
  disableSelectedValuesForOptionList,
  filterSelectedValuesForOptionList,
};
