import { atom } from "jotai";

import { ForwardingAdminUserAuthority } from "../../types/forwarding/adminUser";

import { FORWARDING_ADMIN_AUTH_ATOMS } from ".";

const CURRENT_FORWARDING_ADMIN_AUTH_INFO = atom<{
  id: number;
  authority: ForwardingAdminUserAuthority;
  accountId: string;
  email: string;
  name: string;
}>((get) => {
  const loggedIn = get(FORWARDING_ADMIN_AUTH_ATOMS.ADMIN_LOGGED_IN);

  if (!loggedIn) return;

  if (typeof window === "undefined") return;

  const data = window.localStorage.getItem("currentUser");

  if (!data) return;

  return JSON.parse(data);
});

const CURRENT_PARTNER_ADMIN_AUTH_INFO = atom((get) => {
  const loggedIn = get(FORWARDING_ADMIN_AUTH_ATOMS.PARTNER_ADMIN_LOGGED_IN);

  if (!loggedIn) return;

  if (typeof window === "undefined") return;

  const data = window.localStorage.getItem("currentUser");

  if (!data) return;

  return JSON.parse(data);
});

export default {
  CURRENT_FORWARDING_ADMIN_AUTH_INFO,
  CURRENT_PARTNER_ADMIN_AUTH_INFO,
};
