import { atom } from "jotai";

/**
 * RHF의 초기화 이슈 때문에 number도 string으로 저장한다
 * - undefined나 null로 초기화하면 이후 state셋팅시 오작동하는 부분이 생겨 빈스트링으로 초기화
 */
type DebuggingDataForSlackIssueReport = {
  bidId?: string;
  userId?: string;
  userCompany?: string;
  pageUrl?: string;
};

/**
 * 'admin의 이슈리포트'기능에서 활용되는 debuggingData
 * - App 전역적으로 셋팅이 가능해야하므로 jotai로 관리
 */
const DEBUGGING_DATA_ATOM = atom<
  // pageUrl은 현재 열려있는 페이지의 실시간 정보를 사용하므로, state로 관리하지 않는다
  Omit<DebuggingDataForSlackIssueReport, "pageUrl">
>({});

export type { DebuggingDataForSlackIssueReport };

export default {
  DEBUGGING_DATA_ATOM,
};
