import {
  useAppMutation,
  useAppQuery,
  useAppQueryWithQueryKeyFactory,
} from "@sellernote/_shared/src/services/query";

import { Country, Liner, Port, Zone } from "../../../types/common/common";
import { FileInfo } from "../../../types/common/file";
import { ExchangeRate } from "../../../types/forwarding/trello";

import {
  DELETE_ADMIN_SHIPMENT_COMMENT_LIST_REQ,
  DELETE_ADMIN_SHIPMENT_COMMENT_LIST_REQ_PATH_PARAMS,
  DELETE_ADMIN_SHIPMENT_COMMENT_LIST_RES,
  EDIT_ADMIN_SHIPMENT_COMMENT_LIST_REQ,
  EDIT_ADMIN_SHIPMENT_COMMENT_LIST_REQ_PATH_PARAMS,
  EDIT_ADMIN_SHIPMENT_COMMENT_LIST_RES,
  GET_ADMIN_EXCHANGE_RATE_LIST_REQ,
  SEND_SLACK_MESSAGE_AS_EASY_TO_WORK_BOT_REQ,
} from "../../../api-interfaces/shipda-api/admin/adminCommon";
import {
  GET_ADMIN_SHIPMENT_COMMENT_LIST_REQ,
  GET_ADMIN_SHIPMENT_COMMENT_LIST_RES,
  UPDATE_ADMIN_SHIPMENT_COMMENT_LIST_REQ,
  UPDATE_ADMIN_SHIPMENT_COMMENT_LIST_REQ_PATH_PARAMS,
  UPDATE_ADMIN_SHIPMENT_COMMENT_LIST_RES,
} from "../../../api-interfaces/shipda-api/admin/adminCommon";

export const ADMIN_COMMON_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/COMMON_QUERY" }] as const,

  getZoneList: () =>
    [
      {
        ...ADMIN_COMMON_QUERY_KEY_GEN.all()[0],
        entity: "zoneList",
      },
    ] as const,

  getPortList: () =>
    [
      {
        ...ADMIN_COMMON_QUERY_KEY_GEN.all()[0],
        entity: "portList",
      },
    ] as const,

  getCountryList: () =>
    [
      {
        ...ADMIN_COMMON_QUERY_KEY_GEN.all()[0],
        entity: "countryList",
      },
    ] as const,

  getExchangeList: (params: GET_ADMIN_EXCHANGE_RATE_LIST_REQ) =>
    [
      {
        ...ADMIN_COMMON_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "exchangeList",
      },
    ] as const,

  getLinerList: () =>
    [
      {
        ...ADMIN_COMMON_QUERY_KEY_GEN.all()[0],
        entity: "userLinerList",
      },
    ] as const,

  getShipmentCommentList: (params: GET_ADMIN_SHIPMENT_COMMENT_LIST_REQ) =>
    [
      {
        ...ADMIN_COMMON_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "shipmentCommentList",
      },
    ] as const,
};

function useGetFileUrlList({
  key,
  enabled,
  onSuccess,
  onError,
}: {
  key: string;
  enabled?: boolean;
  onSuccess: (res: FileInfo) => void;
  onError: () => void;
}) {
  const queryResult = useAppQuery<FileInfo>({
    queryKey: ["forwarding", "file", "download", key],
    enabled,
    requestOptions: {
      method: "get",
      path: "/files/download/",
      apiType: "SHIPDA_ADMIN_API",
      params: { key },
    },
    onSuccess,
    onError,
  });

  return { ...queryResult };
}

function useGetZoneList() {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_COMMON_QUERY_KEY_GEN.getZoneList>,
    Zone[]
  >({
    queryKey: ADMIN_COMMON_QUERY_KEY_GEN.getZoneList(),
    requestOptions: {
      method: "get",
      path: "/zones",
      apiType: "SHIPDA_ADMIN_API",
    },
  });

  return { ...queryResult };
}

function useGetPortList() {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_COMMON_QUERY_KEY_GEN.getPortList>,
    Port[]
  >({
    queryKey: ADMIN_COMMON_QUERY_KEY_GEN.getPortList(),
    requestOptions: {
      method: "get",
      path: "/ports",
      apiType: "SHIPDA_ADMIN_API",
    },
  });

  return { ...queryResult };
}
//

function useGetCountryList() {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_COMMON_QUERY_KEY_GEN.getCountryList>,
    Country[]
  >({
    queryKey: ADMIN_COMMON_QUERY_KEY_GEN.getCountryList(),
    requestOptions: {
      method: "get",
      path: "/countries",
      apiType: "SHIPDA_ADMIN_API",
    },
  });

  return { ...queryResult };
}

function useGetExchangeRateList(
  params: GET_ADMIN_EXCHANGE_RATE_LIST_REQ,
  enabled?: boolean,
  onSuccess?: (data: ExchangeRate[]) => void
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_COMMON_QUERY_KEY_GEN.getExchangeList>,
    ExchangeRate[]
  >({
    queryKey: ADMIN_COMMON_QUERY_KEY_GEN.getExchangeList(params),
    requestOptions: {
      method: "get",
      path: "/exchange",
      apiType: "SHIPDA_ADMIN_API",
      params,
    },
    enabled,
    onSuccess,
  });

  return { ...queryResult };
}

function useGetLinerList() {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_COMMON_QUERY_KEY_GEN.getLinerList>,
    Liner[]
  >({
    queryKey: ADMIN_COMMON_QUERY_KEY_GEN.getLinerList(),
    requestOptions: {
      method: "get",
      path: "/liners",
      apiType: "SHIPDA_ADMIN_API",
    },
  });

  return { ...queryResult };
}

function useGetShipmentCommentList(
  params: GET_ADMIN_SHIPMENT_COMMENT_LIST_REQ
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_COMMON_QUERY_KEY_GEN.getShipmentCommentList>,
    GET_ADMIN_SHIPMENT_COMMENT_LIST_RES
  >({
    queryKey: ADMIN_COMMON_QUERY_KEY_GEN.getShipmentCommentList(params),
    requestOptions: {
      method: "get",
      path: `/comment/${params.shipmentId}`,
      apiType: "SHIPDA_ADMIN_API",
    },
  });

  return { ...queryResult };
}

function useUpdateShipmentComment() {
  const mutation = useAppMutation<
    UPDATE_ADMIN_SHIPMENT_COMMENT_LIST_REQ,
    UPDATE_ADMIN_SHIPMENT_COMMENT_LIST_RES,
    UPDATE_ADMIN_SHIPMENT_COMMENT_LIST_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "post",
      path: ({ shipmentId }) => `/comment/${shipmentId}`,

      apiType: "SHIPDA_ADMIN_API",
    },
  });

  return { ...mutation };
}

function useEditShipmentComment() {
  const mutation = useAppMutation<
    EDIT_ADMIN_SHIPMENT_COMMENT_LIST_REQ,
    EDIT_ADMIN_SHIPMENT_COMMENT_LIST_RES,
    EDIT_ADMIN_SHIPMENT_COMMENT_LIST_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ commentId }) => `/comment/${commentId}`,

      apiType: "SHIPDA_ADMIN_API",
    },
  });

  return { ...mutation };
}

function useDeleteShipmentComment() {
  const mutation = useAppMutation<
    DELETE_ADMIN_SHIPMENT_COMMENT_LIST_REQ,
    DELETE_ADMIN_SHIPMENT_COMMENT_LIST_RES,
    DELETE_ADMIN_SHIPMENT_COMMENT_LIST_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "delete",
      path: ({ commentId }) => `/comment/${commentId}`,

      apiType: "SHIPDA_ADMIN_API",
    },
  });

  return { ...mutation };
}

/**
 * slack에 `일하기 쉽다 봇`으로 메시지를 보내는 api
 * - `스레드 시작 메시지(initialMessage), [optional]상세 메시지(detailMessageBlocks), [optional]첨부파일(files)` 형태라면 범용적으로 사용가능하다
 */
function useSendSlackMessageAsEasyToWorkBot() {
  const mutation = useAppMutation<
    SEND_SLACK_MESSAGE_AS_EASY_TO_WORK_BOT_REQ,
    unknown
  >({
    requestOptions: {
      apiType: "SHIPDA_ADMIN_API",
      method: "post",
      path: "/report",
      contentType: "multipart/form-data",
      "X-API-KEY": "easytowork20250325",
    },
  });

  return { ...mutation };
}

const ADMIN_COMMON_QUERY = {
  useGetFileUrlList,
  useGetZoneList,
  useGetPortList,
  useGetCountryList,
  useGetExchangeRateList,
  useGetLinerList,
  useGetShipmentCommentList,
  useUpdateShipmentComment,
  useEditShipmentComment,
  useDeleteShipmentComment,
  useSendSlackMessageAsEasyToWorkBot,
};

export default ADMIN_COMMON_QUERY;
