import { atom } from "recoil";

import {
  BidCreateUserData,
  CargoInfoFormType,
} from "../../../types/forwarding/adminBidCreate";

const USER_DATA_FOR_BID_CREATE = atom<BidCreateUserData>({
  key: "forwarding/adminCreateBid/atoms/USER_DATA_FOR_BID_CREATE",
  default: {
    userId: 0,
    userName: "",
    userCompany: "",
    teamId: 0,
    corpSizeType: "SME",
  },
});

const BID_COUNT_TO_CREATE = atom<number>({
  key: "forwarding/adminCreateBid/atoms/BID_COUNT_TO_CREATE",
  default: 1,
});

// 어떤 페이지에서 들어왔는지를 확인
const CARGO_INFO_FORM_TYPE = atom<CargoInfoFormType>({
  key: "forwarding/adminCreateBid/atoms/CARGO_INFO_FORM_TYPE",
  default: "totalVolume",
});

export default {
  USER_DATA_FOR_BID_CREATE,
  BID_COUNT_TO_CREATE,
  CARGO_INFO_FORM_TYPE,
};
