import { GET_ADMIN_BID_LIST_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBid";

import { atomWithReset } from "jotai/utils";

const SHIPMENT_TABLE_FILTER_LIST = atomWithReset<GET_ADMIN_BID_LIST_REQ>({
  order: "DESC",
  locale: "KR",
  page: 0,
  perPage: 20,
});

export default {
  SHIPMENT_TABLE_FILTER_LIST,
};
