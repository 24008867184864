type SLACK_CHANNEL = "test-일하기쉽다-둥지" | "지원-포워딩";

/**
 * Slack Bot `일하기 쉽다` 인증 토큰
 */
const SLACK_EASY_TO_WORK_BOT_TOKEN =
  "xoxb-2703847581200-2986083118211-f4OX7esW6UvhGpOy8QlP9VB0";

const SLACK_CHANNEL_ID_RECORD: Record<SLACK_CHANNEL, string> = {
  "test-일하기쉽다-둥지": "C02TAU1JL4U",
  "지원-포워딩": "C02R1BN5YA0",
};

/**
 * email별 slack id
 * - name은 쓰지 않으나 식별을 위해 추가함
 * - slack web api (`[GET] https://slack.com/api/users.list`로 조회하여 동적으로 가져오는 것도 가능하나, 아래 이슈 때문에 초기 데이터만 API응답을 넣고 이후는 신규 멤버 추가때마다 수동으로 넣어준다.
 *   - 브라우저에서 Slack Web API를 호출하면 CORS이슈때문에 simple request를 유지해야하는데 이런 제약때문에 react-query 등을 사용하여 캐싱처리하기 어려움
 *   - 멤버의 email, id는 바뀌지 않음
 *   - 신규 멤버추가시 슬랙 프로필을 확인하여 추가하는 것도 간단한 일임.
 */
const SLACK_ID_RECORD: Record<string, { name: string; id: string }> = {
  "song@ship-da.com": { id: "U02KTG79494", name: "송승은" },
  "jongha.kim@ship-da.com": { id: "U02L04Y7G1Y", name: "김종하" },
  "qus@ship-da.com": { id: "U02L06058P5", name: "변형준" },
  "kim62210@ship-da.com": { id: "U02L08U16UB", name: "김형주" },
  "cyshin@ship-da.com": { id: "U02LD6ZF5NH", name: "신채영" },
  "jungwon.lee@ship-da.com": { id: "U02LJD16AAG", name: "이중원" },
  "daeryong.ju@ship-da.com": { id: "U02M79U0FRT", name: "주대룡" },
  "max@ship-da.com": { id: "U02M92KD2RL", name: "손석균" },
  "jhjeong@ship-da.com": { id: "U02MCQPGK9T", name: "정정훈" },
  "hjhwang@ship-da.com": { id: "U02MUCG4TT3", name: "황호진" },
  "gost@ship-da.com": { id: "U03B17A477H", name: "고성태" },
  "yangchan00@ship-da.com": { id: "U03DLBJ5LAH", name: "양찬규" },
  "jwshin@ship-da.com": { id: "U03EMJWFBL4", name: "신지원" },
  "innfi@ship-da.com": { id: "U03JN8RFW2U", name: "박진규" },
  "luke@ship-da.com": { id: "U047ESC1F8Q", name: "김희원" },
  "insik@ship-da.com": { id: "U04CJM4A7T7", name: "성인식" },
  "kym@ship-da.com": { id: "U04DBAS682U", name: "김영미" },
  "ys.choi@ship-da.com": { id: "U04J1SBJGUU", name: "최용석" },
  "iamchho@ship-da.com": { id: "U04LDF16W3V", name: "이치호" },
  "jeonbg@ship-da.com": { id: "U05106VMCFR", name: "전병길" },
  "sangyou@ship-da.com": { id: "U056EGPMTRC", name: "박상유" },
  "leejunhoo@ship-da.com": { id: "U05CC8NMRLZ", name: "이준후" },
  "bomi@ship-da.com": { id: "U06CLLR4GA2", name: "이보미" },
  "juri@ship-da.com": { id: "U06NTJRQQP5", name: "전주리" },
  "hdh05171003@ship-da.com": { id: "U06NW66DL92", name: "황동희" },
  "choihj@ship-da.com": { id: "U06QNGK8J7R", name: "최혁중" },
  "dasom@ship-da.com": { id: "U06T6FW628K", name: "박다솜" },
  "cj@ship-da.com": { id: "U0733HHKD18", name: "이창준" },
  "sh.jeon@ship-da.com": { id: "U07G22Y0E2K", name: "전상훈" },
  "sypark@ship-da.com": { id: "U0835T9E0TV", name: "박소영" },
  "duke@ship-da.com": { id: "U08360DUC4S", name: "이덕규" },
  "ws.jung@ship-da.com": { id: "U08CH417AR0", name: "정원석" },
  "geunyoung.no@ship-da.com": { id: "U08D0EMNBQV", name: "노근영" },
  "ah@ship-da.com": { id: "U08D373UWV9", name: "변용호" },
  "hh.jung@ship-da.com": { id: "U08DFNJNQKB", name: "정현희" },
};

export type { SLACK_CHANNEL };

export {
  SLACK_CHANNEL_ID_RECORD,
  SLACK_EASY_TO_WORK_BOT_TOKEN,
  SLACK_ID_RECORD,
};
